<template>
  <div class="container">
    <div style="">
      <div class="title">物管栈考核系统</div>
    </div>

    <div class="main">
      <van-form ref="form" @submit="onSubmit">
        <div class="form-wrap">
          <van-field
            v-model="username"
            label="账号："
            label-class="label-text"
            type="text"
            name="username"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="password"
            type="password"
            name="password"
            label-class="label-text"
            label="密码："
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </div>
        <div class="btn-wrap">
          <div class="submit-btn" @click="login"><span>登录</span></div>
        </div>
      </van-form>
    </div>

    <div class="foot">
      <div><a href="http://sq.0573vote.com/">专家申请</a></div>
      <div>物管栈（嘉兴）科技有限公司</div>
    </div>

  </div>
</template>

<script>
import { login } from '@/api/login'
import { Form, Field } from 'vant'
import storage from 'store'

export default {
  name: 'Login',
  components: {
    [Form.name]: Form,
    [Field.name]: Field
  },
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    onSubmit (values) {
      console.log(values)
      login(values).then(res => {
        console.log(res)
        storage.set('Access-Token', res.data.token)
        this.$router.push('/')
      })
    },
    login () {
      this.$refs.form.submit()
    }
  }
}
</script>

<style scoped>
.title {
  padding: 100px 0 0 0;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color:rgb(75, 85, 95)
}
.main {
  width: 500px;

}
.form-wrap {
  border-radius: 10px;
  overflow: hidden;
}
.submit-btn {
  height: 70px;
  background-color: #1989fa;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-wrap {
  margin: 36px 0 0 0;
}
</style>
<style>
.label-text{
  width: auto;
}
.foot {
  font-size: 32px;
  text-align: center;
  color: #999;
  padding: 0 0 100px 0;
}
</style>
