import request from '@/utils/request'

const api = {
  login: '/assess/expert/login'
}

export function login (parameter) {
  return request({
    url: api.login,
    method: 'post',
    data: parameter
  })
}

// export function add (parameter) {
//   return request({
//     url: api.add,
//     method: 'post',
//     data: parameter
//   })
// }
